'use client';

import {
  Button,
  Icons,
  MEDIA_LARGE,
  MEDIA_MEDIUM,
  NEUTRAL_WHITE,
  RED_100,
  RED_700,
  SPACING_3,
  Text,
} from '@mnd-frontend/ui';
import { useState } from 'react';
import { styled } from 'styled-components';
import { Tag } from '../ui';

const Section = styled.section`
  margin-bottom: 6rem;
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  row-gap: ${SPACING_3};
  flex-direction: column;
  position: relative;
`;

const P = styled.p`
  opacity: 0.85;
  line-height: 1;
  font-size: 1.1rem;
`;

const H3 = styled.h3`
  opacity: 0.85;
  font-size: 1.25rem;
  margin: 1.5rem 0 0.5rem 0;
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;

  @media (min-width: ${MEDIA_MEDIUM}px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: ${MEDIA_LARGE}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const Li = styled.li``;

const Figure = styled.figure`
  padding: 0;
  margin: 0;
`;

const Figcaption = styled.figcaption``;

const ImgWrapper = styled.div<{ $scale: number; $marginTop: string }>`
  border-radius: 24px;
  background: radial-gradient(#4f4e65, #363645);
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
  i {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }
  img {
    max-width: 100%;
    scale: ${props => props.$scale};
    margin-top: ${props => `calc(${props.$marginTop} )`};
  }
`;

const ExpandButton = styled.button`
  all: unset;
  cursor: pointer;

  &:hover ${ImgWrapper}:after {
    opacity: 1;
  }
  &:hover i {
    opacity: 1;
  }
`;

const Expert = styled.article`
  position: absolute;
  top: 106px;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${NEUTRAL_WHITE};
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  height: 524px;

  button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const ExpertImgWrapper = styled.div`
  border-radius: 24px;
  background: radial-gradient(#4f4e65, #363645);
  aspect-ratio: 1 / 1;
  overflow: hidden;
  height: 100%;
  img {
    max-width: 100%;
  }
`;

export const SectionFourIntro: React.FC<{
  tag: string | null;
  experts:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionFourExperts';
        aspectRatio: number | null;
        profession: string | null;
        description: string | null;
        marginTop: string | null;
        name: string | null;
        scale: number | null;
        image: {
          __typename?: 'AcfMediaItemConnectionEdge';
          node: {
            __typename?: 'MediaItem';
            altText: string | null;
            mediaItemUrl: string | null;
          };
        } | null;
      } | null)[]
    | null;
}> = ({ tag, experts }) => {
  const [showExpert, setShowExpert] = useState<number | null>(null);

  return (
    <Section>
      <Wrapper>
        <Tag as="h3" $color={RED_700} $backgroundColor={RED_100}>
          {tag?.toUpperCase()}
        </Tag>
        <Ul>
          {experts?.map((item, index) => {
            return (
              <Li key={index}>
                <ExpandButton type="button" onClick={() => setShowExpert(index)}>
                  <Figure>
                    <ImgWrapper $scale={item?.scale ?? 1} $marginTop={item?.marginTop ?? ''}>
                      <Icons.Expand $color="NEUTRAL_WHITE" $size="sm" />
                      <img
                        src={item?.image?.node.mediaItemUrl ?? ''}
                        alt={item?.image?.node.altText ?? ''}
                      />
                    </ImgWrapper>
                    <Figcaption>
                      <H3>{item?.name}</H3>
                      <P>{item?.profession}</P>
                    </Figcaption>
                  </Figure>
                </ExpandButton>
              </Li>
            );
          })}
        </Ul>
        {showExpert !== null && (
          <Expert>
            <Button
              type="button"
              onClick={() => setShowExpert(null)}
              configuration="text"
              icon={<Icons.Close $size="sm" />}
              aria-label="Close expert card"
            />

            <ExpertImgWrapper>
              <img src={experts?.[showExpert]?.image?.node.mediaItemUrl ?? ''} alt="" />
            </ExpertImgWrapper>
            <div>
              <Text as="h3" variant="headlineLarge">
                {experts?.[showExpert]?.name}
              </Text>
              <Text as="span" variant="subheading">
                {experts?.[showExpert]?.profession}
              </Text>
              <Text as="p" variant="body" marginTop="SPACING_2">
                {experts?.[showExpert]?.description}
              </Text>
            </div>
          </Expert>
        )}
      </Wrapper>
    </Section>
  );
};
