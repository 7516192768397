'use client';

import {
  MEDIA_LARGE,
  RED_100,
  RED_500,
  RED_700,
  SIZING_10,
  SIZING_4,
  SIZING_5,
  SIZING_8,
} from '@mnd-frontend/ui';
import { styled } from 'styled-components';

const H2 = styled.h2`
  line-height: 1;
  max-width: 900px;
  font-weight: 900;
  color: ${RED_700};
  letter-spacing: -0.2rem;
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_10};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_8};
  }
`;

const Span = styled.span`
  line-height: 0.9;
  font-weight: 900;
  color: ${RED_500};
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_5};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_4};
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 15rem 0;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 10rem 2rem 5rem 2rem;
  }
`;

const Container = styled.div`
  min-height: 65vh;
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 0 5rem;
  }
`;

export const Chapter: React.FC<{ subheader: string | null; header: string | null }> = ({
  subheader,
  header,
}) => {
  return (
    <Container id="pr-report-chapter-section">
      <Section>
        <Wrapper>
          <Span>{subheader}</Span>
          <H2>{header?.toUpperCase()}</H2>
        </Wrapper>
      </Section>
    </Container>
  );
};
