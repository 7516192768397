'use client';

import { MEDIA_LARGE, SIZING_5 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';

const P = styled.p`
  line-height: 1.65;
  font-size: 1.15rem;
`;

const H2 = styled.h2`
  line-height: 1.15;
  font-size: ${SIZING_5};
`;

const Article = styled.article`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  max-height: fit-content;
`;

const Div = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionEightSummary: React.FC<{
  header: string;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionEightDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrSuSectionEightDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ header, description }) => {
  return (
    <Section>
      <Div>
        <Article>
          <H2>{header}</H2>
          {description?.map((item, i) => (
            <P key={i}>
              {item?.paragraphs?.map((text, k) => (
                <Fragment key={k}>
                  {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                    <b>{text?.paragraph}</b>
                  ) : (
                    <>{text?.paragraph}</>
                  )}
                </Fragment>
              ))}
            </P>
          ))}
        </Article>
      </Div>
    </Section>
  );
};
