'use client';

import { MEDIA_LARGE, NEUTRAL_WHITE, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import styled from 'styled-components';

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const H3 = styled.h3`
  font-size: 1.5rem;
  margin: 0.5rem 0 1.5rem 0;
`;

const Blockquote = styled.blockquote`
  text-align: center;
`;

const H4 = styled.h4`
  margin: 0;
  font-size: 1.15rem;
`;

const Author = styled.div<{ $imageUrl: string; $size: string; $position: string }>`
  display: flex;
  padding: 2rem;
  min-height: 500px;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.5) url(${props => props.$imageUrl});
  background-blend-mode: overlay;
  background-size: ${props => props.$size};
  background-position: ${props => props.$position};
  background-repeat: no-repeat;
`;

const Card = styled.div`
  height: fit-content;
  color: ${NEUTRAL_WHITE};
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(35% - 86.5px);
  }
`;

const P = styled.p`
  line-height: 1.65;
  font-size: 1.15rem;
`;

const Article = styled.article`
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
`;

const Div = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFiveSummary: React.FC<{
  header: string;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionFiveDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  quote: string;
  authorSize: string;
  authorMobileSize: string;
  authorPosition: string;
  authorImage:
    | {
        __typename?: 'MediaItem';
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorName: string;
  authorDescription: string;
}> = ({
  header,
  description,
  quote,
  authorSize,
  authorMobileSize,
  authorPosition,
  authorImage,
  authorName,
  authorDescription,
}) => {
  return (
    <Section>
      <Div>
        <Card>
          <Author
            $size={authorSize}
            $position={authorPosition}
            $imageUrl={authorImage?.mediaItemUrl ?? ''}
          >
            <Blockquote>
              <Quote>“</Quote>
              <H3>
                <i>{quote}</i>
              </H3>
            </Blockquote>
            <div>
              <H4>{authorName}</H4>
              <P>{authorDescription}</P>
            </div>
          </Author>
        </Card>
        <Article>
          <H4>{header}</H4>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </Article>
      </Div>
    </Section>
  );
};
