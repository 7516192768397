'use client';

import { MEDIA_LARGE, RED_100, RED_700, SIZING_4, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';

const H4 = styled.h4`
  margin: 0;
  font-size: 1.15rem;
`;

const Wrapper = styled.div`
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
`;

const Li = styled.li`
  display: flex;
  counter-increment: item;
  &:before {
    max-width: 30px;
    font-weight: 700;
    font-size: 2.5rem;
    color: ${RED_700};
    margin-top: -0.5rem;
    content: counter(item) '.';
  }
  @media (min-width: ${MEDIA_LARGE}px) {
    &:before {
      margin-right: 2rem;
    }
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    &:before {
      margin-right: 1rem;
    }
  }
`;

const Ol = styled.ol`
  margin: 0;
  padding: 0;
  display: flex;
  row-gap: 3rem;
  list-style: none;
  counter-reset: item;
  flex-direction: column;
`;

const Inner = styled.div`
  display: flex;
  row-gap: 3rem;
  padding: 3rem;
  border-radius: 24px;
  flex-direction: column;
  background-color: ${RED_100};
`;

const P = styled.p`
  line-height: 1.65;
  font-size: 1.15rem;
`;

const H2 = styled.h2`
  line-height: 1.15;
  font-size: ${SIZING_4};
`;

const Article = styled.article`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
`;

const Div = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionSixSummary: React.FC<{
  header: string;
  itemHeader: string;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionSixDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionSixItems';
        header: string | null;
        description: string | null;
      } | null)[]
    | null;
}> = ({ header, itemHeader, description, list }) => {
  return (
    <Section>
      <Div>
        <Article>
          <H2>{header}</H2>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </Article>
        <Inner>
          <H4>{itemHeader}</H4>
          <Ol>
            {list?.map((item, index) => (
              <Li key={index}>
                <Wrapper>
                  <H4>{item?.header}</H4>
                  <P>{item?.description}</P>
                </Wrapper>
              </Li>
            ))}
          </Ol>
        </Inner>
      </Div>
    </Section>
  );
};
