'use client';

import { Icons, NEUTRAL_WHITE, RED_700, RED_800 } from '@mnd-frontend/ui';
import Link from 'next/link';
import { styled } from 'styled-components';

const FooterComponent = styled.footer`
  height: 248px;
  display: flex;
  align-items: center;
  background-color: ${RED_700};
`;

const StyledLink = styled(Link)`
  color: ${NEUTRAL_WHITE};
  font-size: 1.5rem;
  line-height: 2;
  font-weight: 700;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  i {
    transition: transform 250ms;
  }

  &:hover {
    background: ${RED_800};
    i {
      transform: translateX(10%);
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChapterHeading = styled.span`
  font-size: 3.5rem;
  line-height: 3.5rem;
`;

export const Footer: React.FC<{
  subheader: string | null;
  header: string | null;
  link: {
    __typename?: 'AcfLink';
    url: string | null;
  } | null;
}> = ({ subheader, header, link }) => {
  return (
    <FooterComponent>
      <StyledLink href={link?.url ?? '#'}>
        <TextWrapper>
          <span>{subheader}</span>
          <ChapterHeading>
            <strong>{header}</strong>
            <Icons.ArrowRight $size="xl" aria-hidden="true" />
          </ChapterHeading>
        </TextWrapper>
      </StyledLink>
    </FooterComponent>
  );
};
